<template>
  <div class="container_con" style="background: none;">
	<div v-for="salePlan in salePlanList">
    <el-card style="margin-bottom: 15px;">
      <div class="con_title"><span></span> 销售计划单详情</div>
      <div style="line-height: 24px; font-size: 14px;">
        <el-row :gutter="20" style="margin-bottom: 10px;">
          <el-col :span="6">开票日期：{{ salePlan.sale_plan_date }}</el-col>
          <el-col :span="6">开票人：{{ salePlan.admin_name }}</el-col>
          <el-col :span="6">部门：{{ salePlan.department_name }}</el-col>
          <el-col :span="6">销售单位：{{ salePlan?.merchant?.merchant_name || ''}}</el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">单据编号：{{ salePlan.sale_plan_no }}</el-col>
          <el-col :span="6">单位编号：{{ salePlan?.merchant?.merchant_no }}</el-col>
        </el-row>
      </div>
      <div class="" style="margin-top: 10px; font-size: 14px;">
        <div style="padding-bottom: 20px;">销售产品：</div>
        <div class="">
          <el-table :data="salePlan.salePlanProduct" style="width: 100%">
            <el-table-column label="产品编号" sortable :sort-method="formatterproduct_no">
              <template v-slot="scope">
                {{ scope.row.product.product_no }}
              </template>
            </el-table-column>
            <el-table-column label="品名" width="180"  sortable :sort-method="formatterproduct_name">
              <template v-slot="scope">
                {{ scope.row.product.product_name }}
              </template>
            </el-table-column>
            <el-table-column label="货位名称">
              <template v-slot="scope">
                {{ scope.row.warehouse_name }}
              </template>
            </el-table-column>
            <el-table-column label="型号规格">
              <template v-slot="scope">
                {{ scope.row.product.model }}
              </template>
            </el-table-column>
            <el-table-column label="生产厂家" width="150">
              <template v-slot="scope">
                {{ scope.row.product.manufacturer }}
              </template>
            </el-table-column>
            <el-table-column label="包装单位">
              <template v-slot="scope">
                {{ scope.row.pack_unit_name }}
              </template>
            </el-table-column>
            <el-table-column label="数量" sortable :sort-method="formatterproduct_num">
              <template v-slot="scope">
                {{ scope.row.product_num }}
              </template>
            </el-table-column>
            <el-table-column label="单价">
              <template v-slot="scope">
                {{ scope.row.sale_price }}
              </template>
            </el-table-column>
            <el-table-column label="金额" sortable :sort-method="formattertotal" >
              <template v-slot="scope">
                <span style="color: red;">{{ (parseFloat(scope.row.product_num) * parseFloat(scope.row.sale_price) ||
                  0).toFixed(4)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="批号/生产日期">
              <template v-slot="scope">
                {{ scope.row.batch_number }}
              </template>
            </el-table-column>
            <el-table-column label="有效期至">
              <template v-slot="scope">
                {{ scope.row.valid_until || '长效期' }}
              </template>
            </el-table-column>
            <el-table-column label="注册证名">
              <template v-slot="scope">
                {{ scope.row.product.register_cert_name }}
              </template>
            </el-table-column>
            <el-table-column label="注册证号">
              <template v-slot="scope">
                {{ scope.row.product.register_cert_no }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
    <el-card style="margin-bottom: 15px;">
      <div class="con_title"><span></span> 出库信息</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="单据编号前缀" prop="no">
          <el-select v-model="form.no" placeholder="请选择" style="width: 300px; margin-right: 15px;" filterable>
            <el-option :label="item.value + '__' + item.title" v-for="(item, index) in zjlist" :key="index"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库日期：" prop="sale_out_date">
          <el-date-picker v-model="salePlan.sale_out_date" type="date" value-format="YYYY-MM-DD" placeholder="选择日期"
            style="width:300px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="部门：" prop="department_id">
          <el-cascader style="width:300px" v-model="salePlan.department_id" :options="list"
            :props="{ expandTrigger: 'hover', checkStrictly: true, emitPath: false, value: 'id', label: 'title', children: '_child' }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="人员:" prop="admin_id">
          <el-select v-model="salePlan.admin_id" placeholder="请选择" style="width:300px" filterable>
            <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code" v-for="(item, index) in rylist" :key="index"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="salePlan.remark" placeholder="请输入备注" style="width:300px" />
        </el-form-item>
      </el-form>
    </el-card>
	</div>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm()" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addsalesOut",
  data() {
    return {
      id: '',
	  ids: '',
	  salePlanList: [],
	  successIds: [],
      is_ajax: false,
      form: {

      },
      rules: {
        sale_out_date: [
          { required: true, message: "出库日期不能为空", trigger: "blur" }
        ],
        no: [
          { required: true, message: "编号前缀不能为空", trigger: "blur" }
        ],
        admin_id: [
          { required: true, message: "人员不能为空", trigger: "blur" }
        ],
        department_id: [
          { required: true, message: "部门不能为空", trigger: "blur" }
        ]
      },
      zjlist: [],
      rylist: [],
      hwlist: [],
      list: [],
      data: '',
      sale_plan_product: [],
      shortcuts: [{
        text: '2099-12-31',
        value: '2099-12-31'
      }, {
        text: '长效期',
        value: 'null'
      }]
    };
  },
  created() {
    this.id = this.$route.query.id || '';
	this.ids = this.$route.query.ids || '';
	
    this.getOptions();
	this.get_data();
  },
  methods: {
    formatterproduct_no(a, b) {
      return a.product.product_no.localeCompare(b.product.product_no)
    },
    formatterproduct_name(a, b) {
      return a.product.product_name.localeCompare(b.product.product_name)
    },
    formatterproduct_num(a, b) {
      return Number(a.product_num) - Number(b.product_num);
    },
    formattertotal(a,b){
      return Number((parseFloat(a.product_num) * parseFloat(a.sale_price) || 0).toFixed(4)) - Number((parseFloat(b.product_num) * parseFloat(b.sale_price) || 0).toFixed(4));
    },
    /** 提交按钮 */
    submitForm: async function () {
	  this.is_ajax = true;
	  let promiseList = [];
	  for(let i = 0; i < this.salePlanList.length; i++) {
		  if (!this.successIds.includes(this.salePlanList[i].id)) {
			  let _data = {
				  sale_plan_id: this.salePlanList[i].id,
				  no: this.form.no,
				  sale_out_date: this.salePlanList[i].sale_out_date,
				  department_id: this.salePlanList[i].department_id,
				  admin_id: this.salePlanList[i].admin_id,
				  remark: this.salePlanList[i].remark
			  };
			  let promise = new Promise((resolve, reject) => {
				  this.$httpPost("/backend/SaleOut/save", _data).then((res) => {
					if (res.status == 200) {
						this.successIds.push(_data.sale_plan_id)
						resolve(res.status)
					} else {
					  this.$message.error(res.message);
					  reject(res.message)
					}
				  }).catch((err) => {
					console.log(err);
				  });
			  });
			  promiseList.push(promise);
		  }
	  }
	  try {
	    await Promise.all(promiseList);
  
	    this.$message.success('新增成功');
	    this.$router.go(-1)
	  } catch (error) {
	    console.error("An error occurred:", error);
	  }
	  
	  this.is_ajax = false;
    },
    get_data() {
      this.$httpGet("/backend/SalePlan/getList", { id: this.id, ids: this.ids }).then((res) => {
        if (res.status == 200) {
          this.salePlanList = res.data;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    getOptions() {
      this.$httpGet("/backend/SysConfig/index", {
        size: 10000000,
        groups: '销售出库'
      }).then((res) => {
        if (res.status == 200) {
          this.zjlist = res.data.data;
          if (this.zjlist.length > 0) {
            this.form.no = this.zjlist[0].value
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
      this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
        if (res.status == 200) {
          this.list = res.data.department_list;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
      this.$httpGet("/backend/sys.Admin/salesmanList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.rylist = res.data.data;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;

  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }

  .el-table {
    margin: 30px 0;
  }
}

.top_box {
  display: flex;
  justify-content: space-around;
}

.examin {
  display: flex;
  justify-content: space-between;
}
</style>
